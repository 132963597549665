
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'vuex';

export default defineComponent({
  name: 'user-check',

  computed: {
    ...mapState('settings', ['cidEnabled', 'searchEnabled']),
  },

  //todo re-enable with ResponseID
  // beforeRouteEnter(to, from, next) {
  //   const user = store.state.user;
  //
  //   if (user && !user.questionPassed) {
  //     next({ name: 'question' });
  //   } else {
  //     next();
  //   }
  // },

  async mounted() {
    // Setup timer in case of check failure
    const forceSurveyEnterTimeout = setTimeout(() => {
      this.tryEnterSurvey();
    }, 15 * 1000);

    // Try to fetch CleanID
    try {
      if (this.cidEnabled) await this.fetchCleanID();
    } catch {
      // NOOP
    }

    // Try fo fetch SampleChain
    try {
      if (this.searchEnabled) await this.fetchSearchToken();
    } catch {
      // NOOP
    }

    clearTimeout(forceSurveyEnterTimeout);

    // Enter Survey in any case
    await this.tryEnterSurvey();
  },

  methods: {
    ...mapActions(['getSurveyEntryLink']),
    ...mapActions('cleanID', ['fetchCleanID']),
    ...mapActions('samplechain', ['fetchSearchToken']),

    async tryEnterSurvey() {
      try {
        const link = await this.getSurveyEntryLink();
        window.location.replace(link);
      } catch {
        this.$router.replace({ name: 'error' }).catch(() => void 0);
      }
    },
  },
});
