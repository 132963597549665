import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import { store } from '../store';
import GeneralError from '../views/GeneralError.vue';
import UserCheck from '../views/UserCheck.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'default',
  } as RouteRecordRaw,
  //todo re-enable with ResponseID
  // {
  //   path: '/question',
  //   name: 'question',
  //   component: UserQuestion,
  // },
  {
    path: '/check',
    name: 'check',
    component: UserCheck,
  },
  {
    path: '/error',
    name: 'error',
    component: GeneralError,
  },
];

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (store.state.initializationStatus === 'NOT-INITIALIZED') {
    store
      .dispatch('initialize', to.query)
      .then(() => {
        store.commit('setInitializationStatus', 'INITIALIZED');
        next();
      })
      .catch((err: unknown) => {
        store.commit('setInitializationStatus', 'FAILURE');
        // eslint-disable-next-line
        console.error("Init:", err);
        next();
      });
  } else {
    next();
  }
});

export default router;
