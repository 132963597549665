
import { BbContainer } from '@bitburst-gmbh/bb-components';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'general-error',

  components: {
    BbContainer,
  },
});
