import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bb_container = _resolveComponent("bb-container")!
  const _directive_t = _resolveDirective("t")!

  return (_openBlock(), _createBlock(_component_bb_container, { class: "error bb-margin-top-16" }, {
    default: _withCtx(() => [
      _createElementVNode("h4", null, [
        _withDirectives(_createElementVNode("div", null, null, 512), [
          [_directive_t, 'errorPageErrorTitle']
        ]),
        _withDirectives(_createElementVNode("div", null, null, 512), [
          [_directive_t, 'errorPageErrorMessage']
        ])
      ])
    ]),
    _: 1
  }))
}