import { createStore } from 'vuex';

import { cache } from '../cache';

import actions from './actions';
import { cleanID } from './clean-id';
import mutations from './mutations';
import { samplechain } from './samplechain';
import { settings } from './settings';
import state, { RootState } from './state';
import user from './user';

export const store = createStore<RootState>({
  state,
  mutations,
  actions,
  modules: { user, cleanID, samplechain, settings },
});

store.subscribe((mutation, state) => {
  if (state.initializationStatus === 'INITIALIZED' && state.user) {
    const cachedState = {
      questionsAsked: state.user.questionsAsked,
      questionPool: state.user.questionPool,
      totalSurveyEntries: state.user.totalSurveyEntries,
      entriesLeftBeforeNextQuestion: state.user.entriesLeftBeforeNextQuestion,
    };
    cache.set('user', { version: 1, state: cachedState });
  }
});

export default store;
