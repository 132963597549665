import { Module } from 'vuex';

import { RootState } from '../state';

import { actions } from './actions';
import { mutations } from './mutations';
import { state, SampleChainState } from './state';

export const samplechain: Module<SampleChainState, RootState> = {
  state,
  mutations,
  actions,
  namespaced: true,
};
