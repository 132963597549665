import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/vue';
import { createApp } from 'vue';

import App from './App.vue';
import { i18n } from './i18n';
import { router } from './router';
import { store } from './store';

import './scss/main.scss';

const app = createApp(App);
app.use(i18n).use(router).use(store);
app.mount('#app');

Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', 'api.bitlabs.ai', /^\//],
    }),
  ],
  tracesSampleRate: 0.01,
});
