import { ActionTree } from 'vuex';

import { SampleChain } from '../../api';
import { RootState } from '../state';

import { SampleChainState } from './state';

export const actions: ActionTree<SampleChainState, RootState> = {
  fetchSearchToken({ commit, rootState }) {
    if (!rootState.settings?.searchEnabled) return Promise.resolve();

    const fetchToken = () => {
      return SampleChain.getToken().then(data => {
        const tokens = data.results;
        if (tokens.length <= 0 || !tokens[0]) throw new Error('Could not generate search token');

        return tokens[0].token;
      });
    };

    return fetchToken().then(token => {
      commit('setSearchToken', token);
    });
  },
};
