export type UserState = {
  questionsAsked: number;
  questionPool: Array<number>;
  totalSurveyEntries: number;
  entriesLeftBeforeNextQuestion: number;

  questionPassed: boolean;
  checkPassed: boolean;
};

export function state(): UserState {
  return {
    questionsAsked: 0,
    questionPool: [],
    totalSurveyEntries: 0,
    entriesLeftBeforeNextQuestion: 0,

    questionPassed: false,
    checkPassed: false,
  };
}
