import { Cache, WebCacheProvider } from '@bitburst-gmbh/cache';

type UserCache = {
  state: {
    entriesLeftBeforeNextQuestion?: number;
    questionPool?: Array<number>;
    questionsAsked?: number;
    totalSurveyEntries?: number;
  };
  version: number;
};

type CacheMeta = {
  user?: UserCache;
};

export const cache = new Cache<CacheMeta>({
  cacheName: 'bitlabs-redirect',
  hashSalt: 'sQk54L68$7J2t87',
  provider: new WebCacheProvider(),
});
