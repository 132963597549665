import { Api } from '@bitburst-gmbh/bitlabs-api';
import axios from 'axios';

import { store } from '../store';

export const BitlabsApi = new Api();

BitlabsApi.instance.interceptors.request.use(config => {
  if (store.state.settings?.debug && config.url?.includes('continue')) {
    config.url += '?debug=true';
  }
  if (!!config.headers && store.state.token !== '' && store.state.userID !== '') {
    config.headers['X-Api-Token'] = store.state.token;
    config.headers['X-User-Id'] = store.state.userID;
  } else {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw new axios.Cancel('No Headers set');
  }
  return config;
});
