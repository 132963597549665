import { ActionTree } from 'vuex';

import { CleanID } from '../../api';
import { RootState } from '../state';

import { CleanIDState } from './state';

export const actions: ActionTree<CleanIDState, RootState> = {
  fetchCleanID({ commit, rootState }): Promise<void> {
    return CleanID.cleanID(
      rootState.txID.toString(),
      rootState.surveyID.toString(),
      rootState.appID.toString(),
      rootState.userID.toString()
    ).then(data => {
      commit('setDeviceId', data.DeviceId);
      commit('setDuplicate', data.Duplicate);
      commit('setIsMobile', data.IsMobile);
      commit('setOrScore', data.ORScore);
      commit('setScore', data.Score);
      commit('setTransactionId', data.TransactionId);
    });
  },
};
