export type CleanIDState = {
  deviceId: string;
  duplicate: boolean;
  isMobile: boolean;
  orScore: number;
  score: number;
  transactionId: string;
};

export function state(): CleanIDState {
  return {
    deviceId: '',
    duplicate: false,
    isMobile: false,
    orScore: 0,
    score: 0,
    transactionId: '',
  };
}
