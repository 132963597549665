import { MutationTree } from 'vuex';

import { UserState } from './state';

const rootMutations: MutationTree<UserState> = {
  setQuestionAsked(state, count: number) {
    state.questionsAsked = count;
  },

  setQuestionPool(state, index: Array<number>) {
    state.questionPool = index;
  },

  shiftQuestionPool(state) {
    state.questionPool.shift();
  },

  setTotalSurveyEntries(state, count: number) {
    state.totalSurveyEntries = count;
  },

  setEntriesLeftBeforeNextQuestion(state, count: number) {
    state.entriesLeftBeforeNextQuestion = count;
  },

  incrementQuestionsAsked(state) {
    state.questionsAsked++;
  },

  incrementTotalSurveyEntries(state) {
    state.totalSurveyEntries++;
  },

  decrementEntriesLeftBeforeNextQuestion(state) {
    if (state.entriesLeftBeforeNextQuestion > 0) state.entriesLeftBeforeNextQuestion--;
  },

  questionPassed(state) {
    state.questionPassed = true;
  },

  checkPassed(state) {
    state.checkPassed = true;
  },
};

export default rootMutations;
