import { Module } from 'vuex';

import { RootState } from '../state';

import actions from './actions';
import mutations from './mutations';
import { UserState, state } from './state';

const user: Module<UserState, RootState> = {
  state,
  mutations,
  actions,
  namespaced: true,
};

export default user;
