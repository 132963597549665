declare const IDSuite: IDSuite.IDSuite;
// eslint-disable-next-line @typescript-eslint/no-namespace
declare namespace IDSuite {
  type InputParameter = {
    ChannelId: string;
    EventId: string;
    PanelistId: string;
    RequestId: string;
    onError: (response: CleanIdError) => void;
    onSuccess: (response: CleanIdResponse) => void;
  };
  type CleanIdResponse = {
    DeviceId: string;
    Duplicate: boolean;
    IsMobile: boolean;
    ORScore: number;
    Score: number;
    TransactionId: string;
  };
  type CleanIdError = {
    error: {
      message: string;
    };
  };

  type IDSuite = {
    cleanid: (inputs: InputParameter) => void;
  };
}

function cleanID(
  requestIdentifier: string,
  eventIdentifier: string,
  channelIdentifier: string,
  userIdentifier: string
) {
  return new Promise<IDSuite.CleanIdResponse>((resolve, reject) => {
    IDSuite.cleanid({
      ChannelId: channelIdentifier,
      EventId: eventIdentifier,
      PanelistId: userIdentifier,
      RequestId: requestIdentifier,
      onSuccess: resolve,
      onError: reject,
    });
  });
}

export default { cleanID };
