import { SettingsState } from './state';

export const mutations = {
  setCIDEnabled(state: SettingsState, enabled: boolean) {
    state.cidEnabled = enabled;
  },

  setSearchEnabled(state: SettingsState, enabled: boolean) {
    state.searchEnabled = enabled;
  },

  setDebug(state: SettingsState, enabled: boolean) {
    state.debug = enabled;
  },
};
