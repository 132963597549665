import { MutationTree } from 'vuex';

import { InitializationStatus, RootState } from './state';

const rootMutations: MutationTree<RootState> = {
  setInitializationStatus(state, status: InitializationStatus) {
    state.initializationStatus = status;
  },

  setAppID(state, app: number) {
    state.appID = app;
  },

  setFraudInkTx(state, fraudInkTx: string) {
    state.fraudInkTx = fraudInkTx;
  },

  setUserID(state, userID: string) {
    state.userID = userID;
  },

  setNetworkID(state, networkID: number) {
    state.networkID = networkID;
  },

  setSurveyID(state, surveyID: number) {
    state.surveyID = surveyID;
  },

  setTxID(state, txID: number) {
    state.txID = txID;
  },

  setCountry(state, country: string) {
    state.country = country;
  },

  setToken(state, token) {
    state.token = token;
  },
};

export default rootMutations;
