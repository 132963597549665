import axios from 'axios';

export const SampleChain = axios.create({
  baseURL: process.env.VUE_APP_SC_API_URL,
  timeout: 10 * 1000,
});

const ClientID = process.env.VUE_APP_SC_CLIENT_ID;

export type TokenData = {
  message: string;
  token: string;
  timestamp: string;
};

export type Token = {
  results: Array<TokenData>;
};

function getToken() {
  return SampleChain.get<Token>(`/respondents/get_token/${ClientID}`, {}).then(response => {
    return response.data;
  });
}

export default {
  getToken,
};
