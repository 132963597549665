
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

import { LangCountryMapping } from './i18n';
import { InitializationStatus } from './store/state';

export default defineComponent({
  name: 'app',

  computed: {
    ...mapState(['initializationStatus', 'country']),
  },

  watch: {
    initializationStatus(status: InitializationStatus) {
      if (status === 'INITIALIZED') {
        const language = Object.entries(LangCountryMapping).find(([, item]) =>
          item.includes((this.country as string).toUpperCase())
        );

        this.$i18n.locale = !language ? 'en' : language[0];
        //todo re-enable with ResponseID
        // this.redirectTo('question');
        this.redirectTo('check');
      } else if (status === 'FAILURE') {
        this.redirectTo('error');
      }
    },
  },

  methods: {
    redirectTo(name: string) {
      if (this.$route.name !== name) {
        this.$router.replace({ name, query: this.$route.query }).catch(() => void 0);
      }
    },
  },
});
