import { ActionTree } from 'vuex';

import { cache } from '../../cache';
import { QuestionCount } from '../../i18n';
import { RootState } from '../state';

import { UserState } from './state';

function shuffleArray(array: Array<number>) {
  // https://en.wikipedia.org/wiki/Schwartzian_transform
  return array
    .map(value => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);
}

const userActions: ActionTree<UserState, RootState> = {
  readStateFromCache({ commit }) {
    return cache
      .get('user')
      .then(user => {
        if (user) {
          if (user.state.questionsAsked) commit('setQuestionAsked', user.state.questionsAsked);
          if (user.state.questionPool) commit('setQuestionPool', user.state.questionPool);
          if (user.state.totalSurveyEntries) commit('setTotalSurveyEntries', user.state.totalSurveyEntries);
          if (user.state.entriesLeftBeforeNextQuestion)
            commit('setEntriesLeftBeforeNextQuestion', user.state.entriesLeftBeforeNextQuestion);
        }
      })
      .catch(() => void 0);
  },

  getNextQuestionIndex({ state, dispatch }) {
    if (state.questionPool.length <= 0) {
      dispatch('generateNewQuestionPool');
    }

    return state.questionPool[0];
  },

  generateNewQuestionPool({ commit }) {
    const questionPool = shuffleArray(Array.from(Array(QuestionCount).keys()));

    commit('setQuestionPool', questionPool);
  },
};

export default userActions;
