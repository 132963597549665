import { createI18n } from 'vue-i18n';

const messages: Record<string, Record<string, string>> = {};
const languages = ['de', 'en', 'es', 'fr', 'hi', 'id', 'it', 'pl', 'pt', 'ru', 'th'];

languages.forEach(language => {
  // eslint-disable-next-line import/no-dynamic-require
  messages[language] = require(`%poeditor%/default/${language}.json`);
});

const language =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window.navigator as any).userLanguage || window.navigator.language;

export const QuestionCount = (function () {
  if (messages.en === undefined) return 0;
  return Object.keys(messages.en).filter(entry => entry.startsWith('questionText')).length;
})();

export const CountryIDs: Record<string, number> = {
  en: 100,
  es: 200,
  fr: 300,
  de: 400,
  it: 500,
  pt: 600,
  hi: 700,
  id: 800,
  pl: 900,
  ru: 1000,
  th: 1100,
};

export const LangCountryMapping = {
  es: ['MX', 'ES'],
  fr: ['FR'],
  de: ['DE'],
  it: ['IT'],
  pt: ['PT', 'BR'],
  hi: ['IN'],
  id: ['ID'],
  pl: ['PL'],
  ru: ['RU'],
  th: ['TH'],
};

export const i18n = createI18n({
  legacy: false,
  locale: language.slice(0, 2) || 'en',
  fallbackLocale: 'en',
  silentFallbackWarn: process.env.NODE_ENV !== 'development',
  messages,
});
