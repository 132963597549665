import { ContinueSurveyBody, SampleChainData } from '@bitburst-gmbh/bitlabs-api';
import { LocationQuery, LocationQueryValue } from 'vue-router';
import { ActionTree, Commit } from 'vuex';

import { BitlabsApi } from '../api/bitlabs';

import { RootState } from './state';

function commitNumber(commit: Commit, type: string, payload: null | undefined | number | string) {
  if (payload && payload !== '') {
    const payloadNumber = parseInt(`${payload}`, 10);
    if (!isNaN(payloadNumber)) {
      commit(type, payloadNumber);
    }
  }
}

export function extractQueryValue(queryValue: Array<LocationQueryValue> | LocationQueryValue) {
  if (Array.isArray(queryValue)) return queryValue[0];
  return queryValue;
}

function isEmpty(str?: string) {
  return !str || str.length === 0;
}

const rootActions: ActionTree<RootState, RootState> = {
  async getSurveyEntryLink({ state }) {
    const payload: ContinueSurveyBody = {};
    const sampleChain: SampleChainData = {};

    // Samplechain

    if (!isEmpty(state.samplechain?.searchToken)) {
      sampleChain.search_token = state.samplechain?.searchToken;
    }

    if (Object.keys(sampleChain).length >= 1) {
      payload.sc = sampleChain;
    }

    // CleanID

    if (!isEmpty(state.cleanID?.transactionId)) {
      payload.cid = {
        token: state.cleanID?.transactionId,
      };
    }

    //todo re-enable with ResponseID
    // commit('user/incrementTotalSurveyEntries');
    // commit('user/decrementEntriesLeftBeforeNextQuestion');

    const query = {
      ...(state.fraudInkTx !== '' ? { fitx: state.fraudInkTx } : {}),
      user_id: state.userID,
    };

    return (
      BitlabsApi.client
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- Hack: query has not the correct type
        //@ts-expect-error
        .continueSurvey(state.networkID, state.surveyID, state.txID, payload, { query })
        .then(data => {
          return data.data.data?.link ?? '';
        })
    );
  },

  async initialize(
    { commit, dispatch, state },
    { app, country, fitx, network, survey, token, tx, user_id, ...settingsParams }: LocationQuery
  ) {
    await dispatch('user/readStateFromCache');

    const settingsMissing = !(await dispatch('settings/setSettings', settingsParams));
    if (settingsMissing) throw new Error('Missing required parameter');

    commitNumber(commit, 'setAppID', extractQueryValue(app ?? ''));
    commitNumber(commit, 'setNetworkID', extractQueryValue(network ?? ''));
    commitNumber(commit, 'setSurveyID', extractQueryValue(survey ?? ''));
    commitNumber(commit, 'setTxID', extractQueryValue(tx ?? ''));
    if (country && country !== '') commit('setCountry', country);
    if (fitx && fitx !== '') commit('setFraudInkTx', fitx);
    if (token && token !== '') commit('setToken', token);
    if (user_id && user_id !== '') commit('setUserID', user_id);

    if (
      state.appID <= -1 ||
      state.country === '' ||
      state.networkID <= -1 ||
      state.surveyID <= -1 ||
      state.token === '' ||
      state.txID <= -1 ||
      state.userID === ''
    ) {
      throw new Error('Missing required parameter');
    }
  },
};

export default rootActions;
