import { LocationQuery } from 'vue-router';
import { ActionTree } from 'vuex';

import { extractQueryValue } from '../actions';
import { RootState } from '../state';

import { SettingsState } from './state';

export const actions: ActionTree<SettingsState, RootState> = {
  setSettings({ commit }, { cid, sc_s, debug }: LocationQuery) {
    const cidValue = extractQueryValue(cid ?? '');
    const scSValue = extractQueryValue(sc_s ?? '');
    const debugValue = extractQueryValue(debug ?? '');

    if (cidValue === '' || scSValue === '') return false;

    commit('setCIDEnabled', cidValue === 'true');
    commit('setSearchEnabled', scSValue === 'true');

    commit('setDebug', debugValue === 'true');

    return true;
  },
};
