import { MutationTree } from 'vuex';

import { CleanIDState } from './state';

export const mutations: MutationTree<CleanIDState> = {
  setDeviceId(state, payload: string) {
    state.deviceId = payload;
  },

  setDuplicate(state, payload: boolean) {
    state.duplicate = payload;
  },

  setIsMobile(state, payload: boolean) {
    state.isMobile = payload;
  },

  setOrScore(state, payload: number) {
    state.orScore = payload;
  },

  setScore(state, payload: number) {
    state.score = payload;
  },

  setTransactionId(state, payload: string) {
    state.transactionId = payload;
  },
};
