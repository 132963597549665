import { CleanIDState } from './clean-id/state';
import { SampleChainState } from './samplechain/state';
import { SettingsState } from './settings/state';
import { UserState } from './user/state';

export type InitializationStatus = 'FAILURE' | 'INITIALIZED' | 'NOT-INITIALIZED';

export type RootState = {
  initializationStatus: InitializationStatus;

  appID: number;
  country: string;
  fraudInkTx: string;
  networkID: number;
  surveyID: number;
  // App token needed to avoid an additional web request
  token: string;
  // Internal transaction id
  txID: number;
  userID: string;

  cleanID?: CleanIDState;
  samplechain?: SampleChainState;
  settings?: SettingsState;
  user?: UserState;
};

export default {
  initializationStatus: 'NOT-INITIALIZED',

  appID: -1,
  country: '',
  networkID: -1,
  fraudInkTx: '',
  surveyID: -1,
  token: '',
  txID: -1,
  userID: '',
} as RootState;
