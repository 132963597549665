export type SettingsState = {
  cidEnabled: boolean;
  searchEnabled: boolean;
  debug: boolean;
};

export function state(): SettingsState {
  return {
    cidEnabled: false,
    searchEnabled: false,
    debug: false,
  };
}
